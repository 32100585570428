import React, { useState} from 'react'
import { Modal } from "react-bootstrap";
import { alertActionTypes } from '../../actionTypes';
export default function Popup({state,dispatch}){
    function onCancelClick(){
        dispatch({type:alertActionTypes.CLOSE_POPUP})
    }
    function onOkClick(){
        dispatch({type:alertActionTypes.USER_CONFIRMED})
    }
return <Modal show={state.title} onHide={onCancelClick} contentClassName="form-popup" backdrop="static">
{state.title &&
<Modal.Body>
{state.type!==alertActionTypes.LOADER&&<> <h3 className="confirm-popup-title popup-title">{state.title}</h3>
    <p className="summary-text">{state.subtitle}</p></>
}
{state.type===alertActionTypes.LOADER&&<div className="d-flex align-items-center flex-column p-3">
<div className="loader"></div>
<div className="loader-text mt-2">{state.title}</div>
</div>
}
    {state.type!==alertActionTypes.LOADER&&<div className="d-flex justify-content-end">
 {state.type!==alertActionTypes.ALERT&&<button className="link-button" onClick={onCancelClick}>{state.cancelbutton||'Cancel'}</button>}
 <button className="button btn-primary" onClick={onOkClick}>{state.okbutton||'ok'}</button>
 </div>}
 </Modal.Body>
}
    </Modal>
} 