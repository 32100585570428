import {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import { RemoveRedEye , VisibilityOff} from '@material-ui/icons';
const SignUpPopup=({closeModal,userDetails})=>{
  const [passwordShow,setPasswordShow]=useState(false);
  const loginNow =()=>{
   closePopup();
   document.getElementById("signIn").click();
  }
  const closePopup=()=>{
    setPasswordShow(false);
    closeModal();
  }
  return <Modal
    size="lg" show={!!userDetails}  backdrop="static"
    onHide={closePopup}
  >
  <Modal.Header closeButton={true} closeVariant="white"/>
  <Modal.Body className="fs-36 lh-2 px-5">
    <div className="pb-4">
    Welcome, {userDetails?.name||userDetails?.username}!
    <p className="py-4 mb-0">
    Login Instructions:
    <ul>
      <li>Username:{userDetails?.username}</li>
<li>Password:<div className="d-inline-block me-1" style={{"width":`${(userDetails?.password?.length/1.8)}em`}}>{passwordShow?userDetails?.password:Array(userDetails?.password?.length).fill('*').join("")}</div>{passwordShow?<VisibilityOff className="cursor-pointer" onClick={(e)=>{setPasswordShow(false)}}/>:<RemoveRedEye  className="cursor-pointer" onClick={(e)=>{setPasswordShow(true)}}/>} </li> 
    </ul>
    </p>
    Message:<br/>
    "{userDetails?.message}"</div>    
    <button className="contact mx-auto fs-36 d-block" onClick={loginNow}>Login Now</button>
    </Modal.Body>
  </Modal>
}
export default SignUpPopup;