import React, { useState, useEffect,useContext } from 'react';
import { Link } from "react-router-dom";
import './swift.css';
import cld from '../../assets/images/bcloud.png';
import ccld from '../../assets/images/cloudchotacloud.png';
import { alertActionTypes } from '../../actionTypes';
import { messageStore } from '../../store';
import axios from '../../services/api';
import { UPLOAD_SWIFT_API,GENERATE_QR_API,ERROR_MESSAGE, BASE_URLS } from '../../services/constants';
import qrImage from '../../assets/images/qr.png'
import Modal from 'react-bootstrap/Modal';
import SharePopup from '../sharePopup';

function StoraSwift() {
  const {state,dispatch} = useContext(messageStore);
  const [selectedFile, setSelectedFile] = useState(null);
  const [shareData,setShareData]=useState(null);
useEffect(()=>{
if(selectedFile){
  dispatch({type:alertActionTypes.CONFIRM,title:'Share File',subtitle:'Do you want to share '+selectedFile.name,okbutton:'Yes'});
}
else{
  document.getElementById('fileUpload').value='';
}
},[selectedFile]);
useEffect(() => {
  if(state&&state.type===alertActionTypes.USER_CONFIRMED&&selectedFile){
    uploadFile();
    //setShareData({image:qrImage,url:"http://tinyurl.com/ywx6l9ve"});
  }
  else if (state&&state.type===alertActionTypes.CLOSE_POPUP&&selectedFile){
    setSelectedFile(null);
  }
},[state]);
const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    //setModalIsOpen(true);
  };

  const closeModal = () => {
    setShareData(null);
    // You can reset the selected file or perform other actions here
  };
  function onFileDrop(e){
    setSelectedFile(e.dataTransfer.files[0]);
    e.preventDefault();
    e.stopPropagation();
}
const uploadFile = async ()=>{
  const formData = new FormData();
  formData.append("file",selectedFile);
  // formData.append("object_name",selectedFile.name);
  try {
    dispatch({type:alertActionTypes.LOADER,title:'Uploading File...'});
    const {data} = await  axios.post(UPLOAD_SWIFT_API,formData);
    setSelectedFile(null);
    setShareData({image:`${BASE_URLS}/${GENERATE_QR_API}?url=${data?.file_details.file_url}`,url:data?.file_details.file_url}); 
    dispatch({type:alertActionTypes.SUCCESS,message:data.message});       
  } catch (error) {
    dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
  }
  finally{
    setSelectedFile(null);
    
  }
}
  return (
    <>  
  <div className="d-flex justify-content-between align-items-center pb-5 position-relative">
    <div className="d-flex align-items-center">
      {/* Add your logo image here */}
      <Link to="/" className="plain-link"><img className='icon-img me-4' src={ccld} alt="Logo" /></Link>
      <h2 className="text-header m-0">STORASWIFT</h2>
 </div>
 <div className="fs-32 lh d-none d-sm-block">
 <Link to="/" className="plain-link">STORA</Link>
    </div>
    </div>
      <div className="d-md-flex">
      <div className="col-md-6 upload-container d-flex align-items-center justify-content-center fs-32 lh fw-bold pt-4 mb-4" onDrop={onFileDrop} onDragOver={(e)=>{e.preventDefault();e.stopPropagation();}}>
         <label htmlFor="fileUpload" style={{ cursor: 'pointer' }}>
                       Drag and Upload
          </label>
          <input type="file" id="fileUpload" style={{ display: 'none' }} onChange={handleFileChange} />
      </div>
        <div className="col-md-6 d-flex flex-column justify-content-between ms-md-4">   
            <p className="fs-40 lh">“Effortlessly share files with a single click, ensuring a seamless and hassle-free experience”</p>
            <p className="fs-36 fw-light mb-4">
            Upload your file, and a unique link will be generated for easy sharing with anyone.
            Your file will remain securely stored in Storahub until the recipient acknowledges the download.
            </p>
        </div>

        <SharePopup closeModal={closeModal} shareData={shareData}/>
    {/* <Modal
    size="xl" show={!!shareData}  backdrop="static"
    onHide={closeModal}
  >
  <Modal.Header closeButton={true} closeVariant="white"/>
  <Modal.Body className="d-flex flex-column justify-content-between align-items-center">
    <img src={shareData?.image} className="qr-image"/>
    <div className="fs-36 py-2">-OR-</div>    
    <div className="contact d-flex flex-wrap justify-content-between fs-36"><a href={shareData?.url}>{shareData?.url}</a><button className="copy-link p-0 border-none f-36" onClick={copyContent}>Copy Link</button></div>
    <div className="fs-24 py-4 text-center"><span className="fw-bold">Note:</span> Files will be accessible for <span className="fw-bold">24 hours</span> from the time of uploading. Ensure you download within this timeframe to secure your data.</div>
    </Modal.Body>
  </Modal> */}
</div>
<div className="fs-24 pb-4 footer-note"><span className="fw-bold">Note:</span> Files will be accessible for <span className="fw-bold">24 hours</span> from the time of uploading. Ensure you download within this timeframe to secure your data.</div>
</>
  )

};
export default StoraSwift;