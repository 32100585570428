const validateForm = (form) => {
    for(let i = 0; i < form.length; i++) {
        if(form[i].classList.contains('required')) {
            form[i].setAttribute('required', 'required');
        }
        if(form[i].dataset.minlength) {
            form[i].setAttribute('minlength', form[i].dataset.minlength);
        }
        if(form[i].dataset.pattern) {
            form[i].setAttribute('pattern', form[i].dataset.pattern);
        }
    }
}
export const removeValidation = (form) => {
    for(let i = 0; i < form.length; i++) {
        if(form[i].classList.contains('required')) {
            form[i].removeAttribute('required', 'required');
        }
        if(form[i].dataset.minlength) {
            form[i].removeAttribute('minlength', form[i].dataset.minlength);
        }
        if(form[i].dataset.pattern) {
            form[i].removeAttribute('pattern', form[i].dataset.pattern);
        }
    }
}
export const handleSubmit = (event,callback) => {
    event.preventDefault();
    
    validateForm(event.target);

    if (!event.target.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
    }
    else if(callback){
        callback(event);
        event.preventDefault()
        event.stopPropagation()
    }

    event.target.classList.add('was-validated')    
}
export function filterData(filterFields,data,cond){
    return data.filter((item)=>filterFields.reduce((result,field)=>{
        const value=field.getValue?field.getValue(item):item[field.key];
        if(cond==='or'){
          return result ||(value.search?value.toLowerCase().search(field.value.toLowerCase())>-1:value==field.value);
        }
        else{
            return result && (field.value.find?!!field.value.find((row)=>row.value?row.value.includes(value):row==value):field.value.getTime?(Math.abs(value?.getTime()-field.value.getTime())<=60000):(value.search?value.toLowerCase().search(field.value.toLowerCase())>-1:value==field.value));
        }

    },cond!=='or'));
}
export function sortData(sortFields,data){
    return data.sort((itemA,itemB)=>sortFields.reduce((result,field)=>{
          const {valueA,valueB}=field.getValue?{valueA:field.getValue(itemA),valueB:field.getValue(itemB)}:{valueA:itemA[field.key],valueB:itemB[field.key]}
          if(typeof valueA ==='number')
           return result||((valueA-valueB)*field.order);
           if(typeof valueA ==='object')
           return result||((valueA.getTime()-valueB.getTime())*field.order);
          else
           return result||((valueA.localeCompare(valueB))*field.order);
    },false));
}