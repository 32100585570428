import axios from "axios";
import { BASE_URLS } from "./constants";
//import authHeader from "./auth-header";

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if(error.response.status===401||error.response.status===403){
    return window.location.href="/";
  }
  return Promise.reject(error);
}); 

export default axios.create({
  baseURL: BASE_URLS,
  timeout: 100000,
// headers: authHeader
});