import {React,useState, useContext, useEffect} from 'react';
import { Link } from "react-router-dom";

import cld from '../../assets/images/cloud-empty.png';
import ccld from '../../assets/images/cloudchotacloud.png';
import { ToggleSwitch } from './ToggleSwitch';
import { authStore, messageStore } from '../../store';
import axios from '../../services/api';
import { FETCH_PLAN_DETAILS, ERROR_MESSAGE } from '../../services/constants';
import { alertActionTypes } from '../../actionTypes';

function Plans() {
  const {authDispatch,authState}=useContext(authStore);
  const {state,dispatch} = useContext(messageStore);
  const [plans,setPlans]=useState([]);
  useEffect(()=>{
    getPlanDetails();
  },[]);
  const [form,setForm]=useState({plan_type:false});
  const changePlan=(e)=>{
    setForm({[e.target.name]:e.target.checked});
    getPlanDetails(e.target.checked);
  }
  const getPlanDetails= async (term) => {
    try {
      const response = await axios.post(`${FETCH_PLAN_DETAILS}/${term?'yearly':'monthly'}`,{
      });

      // Handle success if needed
      setPlans(response?.data?.Plans?.plan_details);
    } catch (error) {
      // Handle errors if needed
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
      setPlans([]);
    }
};
  return<>  
  <div className="d-flex justify-content-between align-items-center pb-3 position-relative">
    <div className="d-flex align-items-center">
      {/* Add your logo image here */}
      <Link to="/" className="plain-link"> <img className='icon-img me-4' src={ccld} alt="Logo" /></Link>
      <h2 className="text-header m-0">STORAFLEX</h2>
 </div>
 <div className="fs-32 lh d-none d-md-block">
 <Link to="/" className="plain-link">STORA</Link>
    </div>
    </div>
    <div className="d-flex flex-column align-items-center">
    <div className="fs-36 pb-5">Elevate with Stora Tiers and Unleash the Power of Seamless Sharing!</div>
    <ToggleSwitch name='plan_type' form={form} updateForm={changePlan} label='monthly' label2='yearly'/>
    <div className="d-flex flex-md-row flex-column flex-wrap w-100 align-items-center">
    {plans.map(({plan_name,price,space})=><div className="plan col-md-4 d-flex flex-column align-items-center justify-content-around">
      <div className="fs-36 fw-bold w-50 text-center">{plan_name}</div>
      <div className="fs-36 fw-light">{space}/Rs.{parseFloat(price)}</div>
      <button className="lp-link d-inline-block align-self-end fs-36">
            Buy Now
          </button>
      </div>)
    }
    </div>
    <div className="fs-36 lh fw-light text-center">Stora Storage, where clarity meets affordability – discover straightforward monthly caps and flat pricing for all your storage needs.</div>
    <div className="contact fs-36 text-center mb-3 mt-5">Get started : inquiry@storaa.in/ 8702XXXXXX</div>
    </div>
</>
;
}

export default Plans;