import  {useState, React,useEffect,useRef} from "react";

export function useMenu(callback){
    const [isMenuOpen, setIsMenuOpen]=useState(false);
    const menu=useRef(null);
    const onClickOutside=(event)=>{
        if(isMenuOpen&&!menu.current.contains(event.target)&&!event.target.classList.contains('menu-button')){
          setIsMenuOpen(false);
          if(callback){
            callback();
          }
        }
      }
      useEffect(()=>{
        document.addEventListener("click", onClickOutside,{capture:true});
  
        return () => {
          document.removeEventListener("click", onClickOutside,{capture:true});
        };
      })
      useEffect(()=>{
        if(isMenuOpen){
            const menuParent=menu.current.closest('.position-relative');
           menu.current.style.top=menuParent.offsetHeight+'px';
           const menuOpener=menuParent.querySelector('.active');
           if(menuOpener){
            menu.current.style.right=(menuOpener.offsetParent.offsetWidth-menuOpener.offsetLeft-(1*menuOpener.offsetWidth))+"px";
           }
           else{
           menu.current.style.right=menu.current?.dataset?.right?menu.current.dataset.right+"px":0;
           }
       }
   },[isMenuOpen]);
   return [menu,isMenuOpen,setIsMenuOpen];
}