import Modal from 'react-bootstrap/Modal';
import React, { useState,useContext} from 'react'
import { messageStore} from '../../store';
import { alertActionTypes } from "../../actionTypes";
import { handleSubmit } from '../../services/utils';
import axios from '../../services/api';
import { CHANGE_PASSWORD_API, ERROR_MESSAGE } from '../../services/constants';
const ChangePasswordPopup=({showPopup,closeModal,username})=>{
  const {dispatch} = useContext(messageStore);
  const [state, setState] = useState({
    old_password: "",
    new_password: ""
  });
  const closePopup=()=>{
    setState({old_password:"",new_password:""});
    closeModal();
  }
  const handleChange = evt => {
    const { name, value } = evt.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();

    try {
      const response = await  axios.post(CHANGE_PASSWORD_API, {
      ...state,username
      });

      dispatch({type:alertActionTypes.SUCCESS,message:response?.data?.message||"Password changed Successfully"});
      closePopup();      
    } catch (error) {
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
    }
  };
  return <Modal
    show={!!showPopup}  backdrop="static"
    onHide={closePopup}
  >
  <Modal.Header closeButton={true} closeVariant="white">Change Password</Modal.Header>
  <Modal.Body className="">
  <form onSubmit={(e)=>handleSubmit(e,handleOnSubmit)} className='form form-label-placeholder'>
  <div className="w-100">
        <input
          type="password"
          name="old_password"
          className="required textfield"
          value={state.old_password}
          onChange={handleChange}
          placeholder="Old Password"
        />
        <div className="invalid-feedback">Old Password is required</div>
  </div>
  <div className="w-100">
        <input
          type="password"
          name="new_password"
          className="required textfield"
          value={state.new_password}
          onChange={handleChange}
          placeholder="New Password"
          data-pattern=".{8,16}"
        />
        <div className="invalid-feedback">{state.new_password?'Password should be 8-16 characters':'New Password is required'}</div>
  </div>
  <div className="d-flex justify-content-end pt-2">
 <button className="link-button" type="reset" onClick={closePopup}>Cancel</button>
 <button className="button btn-primary" type="submit" >submit</button>
 </div>
   </form>
    </Modal.Body>
  </Modal>
}
export default ChangePasswordPopup;