import {React,useState,useRef,useEffect} from 'react'
import FilterListIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/Sort';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import SearchIcon from '@material-ui/icons/Search';
import { filterData,sortData} from "../../services/utils";
import { useMenu } from '../../hooks';
import { DeleteOutline } from '@material-ui/icons';

export default function Menubar({fields,setData,data,onSearch=null}){
  const [searchField,setSearchField]=useState(false);
  const [fieldValue,setFieldValue]=useState(null);
  const [freeText,setFreeText]=useState(null);
  const [sortFields,setSortFields]=useState([{key:"",order:1}]);
  const [filterField,setFilterField]=useState(-1);
  const [filterFields,setFilterFields]=useState([]); 
  const [doFilter,setDoFilter]=useState(false);
  const onClickOutsideCB=()=>{
    setFilterField(-1);
}
const [menu,isMenuOpen, setIsMenuOpen]=useMenu(onClickOutsideCB);  
const menuHeight=useRef(null);
  function showSearchField(el){
    if(!freeText){
    if(!searchField){
    setSearchField(true);
    setTimeout(()=>{
      el.target?.parentNode?.nextSibling?.focus()
    },100);
    }
    else{
      setSearchField(false);
    }
    }
  }
  function doFreeSearch(el){
    if(el.keyCode===13&&(freeText||el.target.value)){
      if(onSearch)
      {
       onSearch(el.target.value);
      }
      else{
      setFreeText(el.target.value);
      clearFilters();
      }
    }
  }
  function updateFieldValue(el){
      setFieldValue(el.target.value);
  }
  function showSortField(e){
    e.stopPropagation();
    setIsMenuOpen(1);
  }
  function showFilterMenu(e){
    e.stopPropagation();
    setIsMenuOpen(2);
  }
  function showFilterField(e,index){ 
    e.stopPropagation();
    if(index!==filterField){
    setFilterField(index)
    setTimeout(() => {
      e.target.closest('.filter-menu').querySelector('.filter-field-container>input,.filter-field-container select')?.focus();
    });  
  }
    else
    setFilterField(-1)    
  }
  function changeOrder(index,value){
    sortFields[index].order=value;
    setSortFields([...sortFields]);
  }
  function removeSort(index,e) {
    if(sortFields.length>1){
    sortFields.splice(index,1);
    setSortFields([...sortFields]);
    }
    else{
      setSortFields([{key:"",order:1,title:""}]);
    }
    e.stopPropagation();
  }
  function updateSortField(index,e) {
    const selectedField=fields.find((item)=>item.title===e.target.value);
    sortFields[index].key=selectedField.key;
    sortFields[index].getValue=selectedField.getValue;
    sortFields[index].title=selectedField.title;
    setSortFields([...sortFields]);
  }
  function addAnotherSort(e) {
    const selectedField=fields.find((item)=>item.title===e.target.value);
    sortFields.push({key:selectedField.key,order:1,title:e.target.value,getValue:selectedField.getValue});
    e.target.value="";
    setSortFields([...sortFields]);
  }
  function getRemainingFields(currentIndex){
    return fields.filter((field,index)=>!sortFields.find((sortField,index)=>sortField.key===field.key&&currentIndex!==index))
  }
  function applySort(){
    const validFields=sortFields.filter((field)=>field.key);
    let filteredData=data;
    if(freeText){
      filteredData=filterData(fields.map((field)=>{return {key:field.key,getValue:field.getValue,value:freeText}}),data,'or')
    }
    else if(filterFields.find((field)=>field.value)){
      filteredData=filterData(filterFields.filter((field)=>!!field.value&&(field.type!=="list"||(field.value.length!==field.list.length&&field.value.length>0))),data,'and')
    }
    if(validFields.length){
    setData([...sortData(validFields,[...filteredData])]);
    }
    else{
    setData(filteredData);
    }
    if(isMenuOpen===1)
    setIsMenuOpen(false);
  }
  function renderFilterField() {
 if(filterFields[filterField].type==="list"){
      if(!menuHeight.current){
        menuHeight.current=menu.current.offsetHeight+'px';
      }
      return <div className="filter-list" style={{height:menuHeight.current}}>
      <div className="d-flex align-items-center"> 
        <input type="checkbox" id={"all"+filterField} onChange={(e)=>updateFilterField("all",e.target.checked)} checked={filterFields[filterField].value?filterFields[filterField].value.length===filterFields[filterField].list.length:null} className="me-1"/> <label className="text-blue" htmlFor={"all"+filterField}>All</label>
        </div>
      {filterFields[filterField].list.map((item,index)=><div key={index} className="d-flex align-items-center"> 
        <input type="checkbox" id={(item.display_value||item)+index} onChange={(e)=>updateFilterField(item,e.target.checked)} checked={filterFields[filterField].value?filterFields[filterField].value.find((value)=>value.display_value?value.display_value==item.display_value:value===item):null} className="me-1"/> <label className="text-blue" htmlFor={(item.display_value||item)+index}>{(item.display_value||item)}</label>
        </div>)}
      </div>
    }
    else{
      return <input
      type="text"
      key={filterField}
      value={filterFields[filterField].value||""}
      onBlur={applyFilter}
      onKeyDown={(e)=>{if(e.keyCode===13)applyFilter()}}
 onChange={(e)=>updateFilterField(e.target.value)}
/> 
    }
  }
  function updateFilterField(value,checked){
    if(filterFields[filterField].type==="list"){
      if(checked){
        if(value==="all"){
          filterFields[filterField].value=[...filterFields[filterField].list];
        }
        else if(filterFields[filterField].value){
          filterFields[filterField].value.push(value);
        }
        else
        filterFields[filterField].value=[value];
      }
      else{
        if(value==="all")
        filterFields[filterField].value=null;
        else
        filterFields[filterField].value.splice(filterFields[filterField].value.findIndex((item)=>item===value),1);
      }
      applyFilter();
    }
    else{
    filterFields[filterField].value=value;
    if(filterFields[filterField].type==="date"||filterFields[filterField].type==="datetime"){
      applyFilter();
    }
    }
    setFilterFields([...filterFields])
  }
  function clearFilters(e) {
   setFilterFields(filterFields.map((field)=>({...field,value:""})));
   if(e?.target){
    applyFilter();
   }
  }
  function applyFilter(){
    if(freeText){
      setFreeText(null);
      setFieldValue(null);
    }
    else{
      setDoFilter(!doFilter);
    }
  }
  useEffect(()=>{
    applySort();
  },[data,freeText,doFilter]); 
  useEffect(()=>{
    setSearchField(false);
    setFieldValue(null);
    setFreeText(null);
    setSortFields([{key:"",order:1}]);
    setFilterField(-1);
    setFilterFields(fields.map((field)=>({...field,value:''}))); 
  },[data])
    return <div className="menuBar d-flex justify-content-end align-items-center position-relative my-2">
      <button className={`plain-button menu-button filter-button text-blue ${(isMenuOpen==2||filterFields.find((field)=>field?.value?.length))&&'active'}`} onClick={showFilterMenu}><FilterListIcon/>Filter</button>
      <button className={`plain-button menu-button sort-button text-blue ${(isMenuOpen==1||sortFields.find((field)=>field?.key?.length))&&'active'}`} onClick={showSortField}><SortIcon/>Sort</button>
      <button className={`plain-button menu-button search-button text-blue ${searchField&&'active'}`} onClick={showSearchField}><SearchIcon/></button>
      {searchField&&<input className="plain-button menu-field" type="text" placeholder="Type to search..." value={fieldValue||""} onKeyDown={doFreeSearch} onChange={updateFieldValue}/>}
      {isMenuOpen==1&&<div className="position-absolute sort-menu text-blue" ref={menu}>
               {sortFields.map((field,index)=><div key={index} className="d-flex justify-content-between align-items-center sort-menu-item sort-menu-item-1">
               <label htmlFor={"sortfield"+index} >Sort by</label>
               <select id={"sortfield"+index} className="mx-3 sort-select" value={field.title} onChange={(e)=>updateSortField(index,e)}>
               <option value=""></option>
               {getRemainingFields(index).map((field,index)=><option key={field.title} value={field.title}>{field.title}</option>)}
               </select>
               <div className="sort-button-toggle me-3">
                 <button className={`plain-button text-blue me-1 ${field.order==1&&'active'}`} onClick={(e)=>changeOrder(index,1)}>A &rarr; Z</button>
                 <button className={`plain-button text-blue ${field.order!==1&&'active'}`} onClick={(e)=>changeOrder(index,-1)}>Z &rarr; A</button>
                 </div>
                <button className="plain-button text-blue p-0" onClick={(e)=>{removeSort(index,e)}}><DeleteOutlineIcon/></button>
               </div>)}
               <div className="d-flex justify-content-between align-items-center sort-menu-item sort-menu-item-2">
               <div>
                 { fields.length!==sortFields.length&&<select onChange={addAnotherSort} value="">
                  <option value="" disabled>Add another sort</option>
               {getRemainingFields().map((field,index)=><option key={field.key} value={field.title}>{field.title}</option>)}
                    </select>}
                   </div>
                    <div>
                      <button className="link-button p-2 me-1 text-blue" onClick={(e)=>setIsMenuOpen(false)}>cancel</button>
                      <button className="button btn-primary py-2 px-3" onClick={applySort}>apply</button>
                      </div>
                 </div>
      </div>}
      {isMenuOpen==2&&<div className="position-absolute filter-menu d-flex" ref={menu}>
      <div className="px-0">
               {filterFields.map((field,index)=><div>
                 <button className={`plain-button menu-button w-100 filter-menu-button text-blue ${index===filterField&&'active'} ${field.value&&(!Array.isArray(field.value)||field.value.length>0)&&'font-weight-bold'}`} key={index} onClick={(e)=>showFilterField(e,index)}>{field.title}{index!==filterField&&field.value&&(!Array.isArray(field.value)||field.value.length>0)&&<span> - {Array.isArray(field.value)?(field.value.length===field.list.length?'Áll':field.value.join(', ')):field.value}</span>}</button>
               </div>)} </div>
                 {filterField>=0&&<div className={`filter-field-container ${filterFields[filterField].type}`}>{renderFilterField()}
                  </div>}
                  {filterFields.find((field)=>field.value)&&<button className="clear-filter-button plain-button p-0 text-blue" onClick={clearFilters}>Clear All</button>}
                 </div>}
      </div>
}