import React, { useState } from "react";
import "./onboarding.css";
import Login from "../login/Login";
import SignUp from "../signup/SignUp";

export default function Onboarding() {
  const [type, setType] = useState("signIn");
  
  const handleOnClick = (text) => {
    console.log(type, text)
    if (text !== type) {
      setType(text);
    }
  };
  const containerClass =
    "container " + (type === "signUp" ? "right-panel-active" : "");
  return (
    <div className="Onboarding on-body">
      <h3 className="onboard-header" >Namaste! Begin your adventure with <br/>STORA – Your Gateway to Infinite Storage.</h3>
      <div className={containerClass} id="container">
        {(type === "signUp" ) ? <SignUp  handleOnClick={handleOnClick} />  :  <Login handleOnClick={handleOnClick} />}
        <div className="overlay-container col-sm-6 col-12">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h1>Welcome Back!</h1>
              <p>
                To keep connected with us please login with your personal info
              </p>
              <button
                className="ghost"
                id="signIn"
                onClick={() => handleOnClick("signIn")}
              >
                Sign In
              </button>
            </div>
            <div className="overlay-panel overlay-right">
              <h1>Hey friend, what's up?</h1>
              <p>Unlock a world of possibilities—enter your details and dive into our cloud storage experience!</p>
              <button
                className="ghost "
                id="signUp"
                onClick={() => handleOnClick("signUp")}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
