import React, { useState,useContext} from 'react'
import { useHistory } from 'react-router-dom';

import { handleSubmit } from '../../services/utils';
import axios from '../../services/api';
import { LOGIN_API, ERROR_MESSAGE, GOOGLE_AUTH } from '../../services/constants';
import { authStore ,messageStore} from '../../store';
import { alertActionTypes,authActionTypes } from "../../actionTypes";
import { GoogleLogin } from '@react-oauth/google';
function Login({ handleOnClick }) {
  const history = useHistory();
  const {authDispatch}=useContext(authStore);
              const {dispatch} = useContext(messageStore);
              const [state, setState] = React.useState({
                username: "",
                password: ""
              });

  const handleChange = evt => {
    const { name, value } = evt.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    dispatch({type:alertActionTypes.LOADER,title:'Logging In...'});
    const { username, password } = state;

    try {
      const response = await  axios.post(LOGIN_API, {
      ...state
      });

      authDispatch({type:authActionTypes.LOGIN_SUCCESS,username,...response?.data});
      axios.defaults.headers.common["Authorization"]=`Bearer ${response?.data?.tokens?.access_token}`;
      history.push('');      
    } catch (error) {
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
    }
  };
  const responseMessage = async (gresponse) => {
    try {
      dispatch({type:alertActionTypes.LOADER,title:'Logging In...'});
      const response = await  axios.get(GOOGLE_AUTH, {
      params:{
        token:gresponse?.credential
      }
      });

      authDispatch({type:authActionTypes.LOGIN_SUCCESS,...response?.data});
      axios.defaults.headers.common["Authorization"]=`Bearer ${response?.data?.tokens?.access_token}`;
      history.push('');      
    } catch (error) {
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
    }
};
const errorMessage = (error) => {
    console.log(error);
};
  return (
    <div className="form-container sign-in-container col-sm-6 col-12">
      <form onSubmit={(e)=>{handleSubmit(e,handleOnSubmit)}}>
        <h1 style={{ color: '#00B1FF' }}>Vibe in</h1>
        <div className="w-100">
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={state.username}
          onChange={handleChange}
          className="required"
        />
        <div className="invalid-feedback">Username is required</div>
        </div>
        <div className="w-100">
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={state.password}
          onChange={handleChange}
          className="required"
        />
        <div className="invalid-feedback">Password is required</div>
        </div>
        <button className="mt-2 mb-3" type="submit">Sign In</button>
        {/* <a href="#">Forgot your password?</a> */}
        <div class="or-separater text-blue w-80"></div>
        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} shape="pill" type="icon"/>
        <div className="d-sm-none capitalize text-blue">Not Registered yet? <button className="plain-button px-0 text-blue" onClick={()=>{document.getElementById('signUp').click();}}><u>Sign Up</u></button></div>
        </form>

        {/* <div>
          <button
            id="signUp"
            onClick={() =>  handleOnClick("signUp")}
          >
            Sign Up
          </button>
        </div> */}
    </div>
  );
}

export default Login;
