import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import StoraLanding from './components/landing/StoraLanding';
import Login from './components/login/Login';
import Onboarding from './components/onboarding/Onboarding';
import SignUp from './components/signup/SignUp';
import PlansPage from './components/plans/Plans';
import ContactUsPage from './components/contact/ContactUs';
import StoraSwift from './components/storaswift/Storaswift';
import StoraHub from './components/home/StoraHub';
import { AuthorizedRoute } from './authorized.route';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/onboard" component={Onboarding} />
        {/* <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} /> */}
        <Route exact path="/plans" component={PlansPage} />
        <Route exact path="/swift" component={StoraSwift} />
        {/* <Route exact path="/jpay" component={JSPayment} /> */}
        <AuthorizedRoute render={(props)=>(
         props?.loggedIn ?
        <Switch>
         <Route exact path="/home" component={StoraHub} />   
         <Redirect from="" to="/home" />
        </Switch>
          :
          <Switch>
          <Route exact path="" component={StoraLanding}/>
          <Redirect from="*" to="" />
          </Switch>
        )}/>
        <Route exact path="/" component={StoraLanding} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
