import { authActionTypes } from "../actionTypes";


export function authentication(state, action) {
  switch (action.type) {
    case authActionTypes.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        ...action
      };
    case authActionTypes.LOGOUT:
      return {};
    default:
      return state
  }
}