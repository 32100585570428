import { alertActionTypes } from "../actionTypes";

export function alert(state = {}, action) {
    switch (action.type) {
      case alertActionTypes.SUCCESS:
        return {
          type: 'toaster-success',
          message: action.message
        };
      case alertActionTypes.ERROR:
        return {
          type: 'toaster-error',
          message: action.message
        };
      case alertActionTypes.CONFIRM:
      case alertActionTypes.CLOSE_POPUP:
      case alertActionTypes.USER_CONFIRMED:
      case alertActionTypes.ALERT:
      case alertActionTypes.LOADER:
        return {
          ...action
        };
      case alertActionTypes.CLEAR:
        return {};
      default:
        return state
    }
  }