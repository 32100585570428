module.exports.BASE_URLS = process.env.REACT_APP_API_BASE_URL
module.exports.GENERATE_QR_API="stora-qr"
module.exports.CREATE_USER_API="create-user"
module.exports.FETCH_USER_DETAILS="fetch-user-details"
module.exports.GET_HUB_DETAILS='get-hub-details'
module.exports.DELETE_FILE="deletefile"
module.exports.UPLOAD_FILE="stora-upload"
module.exports.DOWNLOAD_FILE="downloadfile"
module.exports.FETCH_PLAN_DETAILS='plans'
module.exports.SHARE_FILE="sharefile"
module.exports.GOOGLE_AUTH="auth"
module.exports.UPLOAD_SWIFT_API="swift-uploadfile"
module.exports.CHANGE_ROLE_API="change-role"
module.exports.CHANGE_PASSWORD_API="change-password"
module.exports.LOGIN_API="stora-login"
module.exports.ERROR_MESSAGE="Oops something went wrong!!";