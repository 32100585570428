import { Modal } from "react-bootstrap"
import { alertActionTypes } from "../../actionTypes";
import { messageStore } from "../../store";
import { useContext } from "react";
import copyIcon from "../../assets/images/copy-icon.svg";
const SharePopup= ({shareData,closeModal})=>{
    const {state,dispatch} = useContext(messageStore);

    const copyContent = async () => {
        try {
          await navigator.clipboard.writeText(shareData.url);
          dispatch({type:alertActionTypes.SUCCESS,message:`Link Copied`});
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
      }
    return <Modal
    size="xl" show={!!shareData}  backdrop="static"
    onHide={closeModal}
  >
  <Modal.Header closeButton={true} closeVariant="white"/>
  <Modal.Body className="d-flex flex-column justify-content-between align-items-center">
    <img src={shareData?.image} className="qr-image"/>
    <div className="fs-36 py-2">-OR-</div>    
    <div className="contact fs-36"><a href={shareData?.url}>{shareData?.url}</a> <img src={copyIcon} title="Copy Link" className="cursor-pointer" onClick={copyContent} width={20}/></div>
    <div className="fs-24 py-4 text-center"><span className="fw-bold">Note:</span> Files will be accessible for <span className="fw-bold">{shareData?.hours||24} hour{!shareData?.hours||shareData?.hours!==1?'s':''}</span> from the time of uploading. Ensure you download within this timeframe to secure your data.</div>
    </Modal.Body>
  </Modal>
}
export default SharePopup;