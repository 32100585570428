

import React from 'react';
import './header.css'; 
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div className="header d-sm-flex justify-content-between">
      <div className="d-none d-sm-flex align-items-center fw-bold left-menu">
     <Link to="/" className="plain-link"> STORA</Link>
      </div>
      <div className="right-menu d-flex align-items-center justify-content-between">
        <div className="menu-item">    <a href="/plans" className=" underLine2 hide_on_responsive">
              <div className="headr-btn-txt"> Plans and Pricing</div>
          </a></div>
        <div className="menu-item">  <a href="/swift" className=" underLine2 hide_on_responsive">
              <div className="headr-btn-txt">StoraSwift</div>
          </a></div>
        <div className="menu-item"  >   <Link to="/onboard" className="lp-link d-inline-block ms-auto">
            Log In
          </Link></div>
      </div>
    </div>
  );
};

export default Header;
