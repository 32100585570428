import React, { useState } from "react";

export function ToggleSwitch({label,label2,name,form,updateForm}) {
  return (
      <div className="form-field d-flex align-items-center">
      <label className="form-label mb-0 me-2 fs-24 cursor-pointer" htmlFor={`switch${name}`}>
      {label}
          </label>
    <label className="toggle-switch">
      <input type="checkbox" id={`switch${name}`} checked={form[name]} name={name} onChange={updateForm} />
      <span className="switch" />
    </label>
    {label2&&<label className="form-label mb-0 ms-2 fs-24 cursor-pointer" htmlFor={`switch${name}`}>
      {label2}
          </label>}
    </div>
  );
}