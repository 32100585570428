import React, { useState, useEffect,useContext } from 'react';
import './home.css';
import { useHistory ,Link} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import storapic from "../../assets/images/stora-pic.png";
import file from "../../assets/images/file.png";
import tagline from "../../assets/images/tagline.png";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cld from '../../assets/images/cloud-empty.png';
import ccld from '../../assets/images/cloudchotacloud.png';
import download from '../../assets/images/download.svg';
import share from '../../assets/images/share.svg';
import trash from '../../assets/images/trash.svg';
import locked from '../../assets/images/locked.svg';
import axios from '../../services/api';
import { authStore ,messageStore} from '../../store';
import { alertActionTypes,authActionTypes } from "../../actionTypes";
import { FETCH_USER_DETAILS, GET_HUB_DETAILS,DOWNLOAD_FILE,ERROR_MESSAGE,DELETE_FILE,UPLOAD_FILE,SHARE_FILE, BASE_URLS, GENERATE_QR_API } from '../../services/constants';
import { useMenu } from '../../hooks';
import BuyNowPopup from '../buynowPopup';
import SharePopup from '../sharePopup';
import ChangePasswordPopup from '../changePasswordPopup';
import Menubar from '../MenuBar';

const generateVideoThumbnail = (file) => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const video = document.createElement("video");

    // this is important
    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      let ctx = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL("image/png"));
    };
  });
};
function StoraHub() {
  const {authDispatch,authState}=useContext(authStore);
  const {state,dispatch} = useContext(messageStore);
  const [userData,setUserData]=useState({});
  const [cardData, setCardData] = useState([]);
  const [filteredCardData,setFilteredCardData]=useState([]);
  const [showChangePasswordPopup,setShowChangePasswordPopup]=useState(false);
  const history = useHistory();

  // State for file upload
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isShareFile, setIsShareFile] = useState(false);
  const [menu,isMenuOpen, setIsMenuOpen]=useMenu();
  const [selectedFile,setSelectedFile]=useState(null);  
  const [showPlanPopup,setShowPlanPopup]=useState(false);
  const [popupData,setPopupData]=useState(null);
  useEffect(()=>{
   getUserDetails();
   getHubDetails();
   window.addEventListener("beforeunload", saveUserData);
   return () => {
     window.removeEventListener("beforeunload", saveUserData);
   };
  },[]);
  useEffect(()=>{
      setFilteredCardData([...cardData]);
  },[cardData])
  useEffect(() => {
    if(state?.type===alertActionTypes.USER_CONFIRMED){
      if(selectedFile){
        if(isShareFile){
          shareFile();
        }
        else{
          deleteFile();
        }
      }
      else
      uploadFile();
    }
    else if (state?.type===alertActionTypes.CLOSE_POPUP){
      if(selectedFile){
      //setIsMenuOpen(false);
      setSelectedFile(null);
      setIsShareFile(false);
      }
      else{
        setUploadedFile(null);
        if(document.getElementById('fileUpload'))document.getElementById('fileUpload').value='';
      }
    }
    // else if (state&&state.type===alertActionTypes.CONFIRM&&isMenuOpen){
    //   setSelectedFile(isMenuOpen.file_name);
    // }
  },[state]);
  const saveUserData=()=>{
    sessionStorage.setItem("user",btoa(JSON.stringify(authState)));
  }
  const getUserDetails = async () => {
    try {
      const response = await axios.get(`${FETCH_USER_DETAILS}/${authState.username}`
      // ,{
      //   headers:{
      //     Authorization: 'Bearer ' + authState.session_id
      //   }
      // }
      );

      // Handle success if needed
      setUserData(response?.data["user details"]);
      if(!response?.data["user details"]?.plan_name){
        setShowPlanPopup(true);
      }
    } catch (error) {
      // Handle errors if needed
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
      setShowPlanPopup(true);
    }
};
const getHubDetails = async () => {
  try {
    const response = await axios.get(`${GET_HUB_DETAILS}/${authState.username}`
    // ,{
    //   headers:{
    //     Authorization: 'Bearer ' + authState.session_id
    //   }
    // }
    );

    // Handle success if needed
    setCardData(response?.data["user details"]?.files);
  } catch (error) {
    // Handle errors if needed
    dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
  }
  finally{
    dispatch({type:alertActionTypes.CLOSE_POPUP});
  }
};
const downloadFile = async (file)=>{
  try {
    dispatch({type:alertActionTypes.LOADER,title:'Downloading File...'});
    const response = await axios.get(DOWNLOAD_FILE,{
      // headers:{
      //   Authorization: 'Bearer ' + authState.session_id
      // },
      params:{
        folder_name:authState.username,
        file_name:file.file_name
      },
      responseType:'blob'
    });
    const new_blob = new Blob( [ response ]);
      const fileURL = window.URL.createObjectURL(new_blob);
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = isMenuOpen.file_name;
      alink.click();
  } catch (error) {
    // Handle errors if needed
    dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.detail||ERROR_MESSAGE});
  }
  finally{
    dispatch({type:alertActionTypes.CLOSE_POPUP});
    //setIsMenuOpen(false);
  }
}
const deleteFile = async ()=>{
  try {
    dispatch({type:alertActionTypes.LOADER,title:'Deleting File...'});
    const response = await axios.get(DELETE_FILE,{
      // headers:{
      //   Authorization: 'Bearer ' + authState.session_id
      // },
      params:{
        username :authState.username,
        file_name:selectedFile
      }
    });
    getUserDetails();
    getHubDetails();

  } catch (error) {
    // Handle errors if needed
    dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.detail||ERROR_MESSAGE});
  }
  finally{
    setSelectedFile(null);
  }
}
const shareFile = async ()=>{
  try {
    dispatch({type:alertActionTypes.LOADER,title:'Sharing File...'});
    const {data} = await axios.get(SHARE_FILE,{
      // headers:{
      //   Authorization: 'Bearer ' + authState.session_id
      // },
      params:{
        folder_name :authState.username,
        file_name:selectedFile
      }
    });
    dispatch({type:alertActionTypes.CLEAR});
    setPopupData({image:`${BASE_URLS}/${GENERATE_QR_API}?url=${data?.file_url}`,url:data?.file_url,hours:1}); 
  } catch (error) {
    // Handle errors if needed
    dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.detail||ERROR_MESSAGE});
  }
  finally{
    setSelectedFile(null);
    setIsShareFile(false);
  }
}
const uploadFile=async ()=>{
  const formData = new FormData();
  formData.append("file",uploadedFile);
  formData.append("username",authState.username);
  try {
    dispatch({type:alertActionTypes.LOADER,title:'Uploading File...'});
    const {data} = await  axios.post(UPLOAD_FILE,formData);
    const newCard = {
      created_at: new Date(),
      file_name: uploadedFile.name,
      thumbnail:uploadedFile.type.includes('video')? await generateVideoThumbnail(uploadedFile):uploadedFile.type.includes('image')?URL.createObjectURL(uploadedFile):null
    };
    setCardData((prevData) => [...prevData, newCard]);
    getUserDetails();
    dispatch({type:alertActionTypes.SUCCESS,message:data.message});       
  } catch (error) {
    dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
  }
  finally{
    setUploadedFile(null);
    document.getElementById('fileUpload').value='';
  }
}
  const handleFileUpload = async (event) => {
    if(userData?.plan_name){
    const file = event.target.files && event.target.files.length > 0 ? event.target.files[0] : null;

    if (file) {
      setUploadedFile(file);
      dispatch({type:alertActionTypes.CONFIRM,title:'Upload File',subtitle:'Do you want to upload '+file.name,okbutton:'Yes'}); 
    }
  }
  };
  function onFileDrop(e){
    handleFileUpload({target:{files: e.dataTransfer.files}});
    e.preventDefault();
    e.stopPropagation();
}
const logOut=()=>{
  authDispatch({type:authActionTypes.LOGOUT});
  axios.defaults.headers.common["Authorization"]=undefined;
  history.push('');   
}
  return (
    <div className="home">
      {/* Header Row */}
      <div className={`d-flex justify-content-between header position-relative align-items-center ${cardData.length>0&&'pb-0'}`}>
      <div className="d-flex justify-content-between align-items-center col-9 flex-wrap flex-shrink-1">
        <div className="d-flex align-items-center w-100">
          {/* Add your logo image here */}
          <Link to="/" className="plain-link"> <img className='icon-img me-4 position-sm-absolute' src={ccld} alt="Logo" /></Link>
          <h2 className="text-header m-0 d-sm-flex justify-content-between align-items-center w-100 flex-wrap">STORAHUB
          <span className="space-header d-block">Space : {parseFloat(userData?.hub_used||0)}/{parseFloat(userData?.hub_size||0)} gb</span>
          </h2>
        </div>
        <div className="progress-container">
            <div className="progress-bar" style={{width:`${(userData?.hub_used?(parseFloat(userData?.hub_used||0)/parseFloat(userData?.hub_size)):0)*100}%`}}></div>
          </div>
        </div>
      <div className="text-right d-flex">
          <div>
          <div className="hw-font fs-32 lh">namaste</div>
          <div className="fs-48 position-relative">{authState?.name||authState?.username}{isMenuOpen===true&&<div className="position-absolute d-flex flex-column menu" data-right="-36" ref={menu}>
          <button onClick={()=>history.push('/plans')}>{userData?.plan_name?'Upgrade':'Buy'} Plan</button>
<button onClick={()=>setShowChangePasswordPopup(true)}>Change Password</button>
<button onClick={logOut}>Logout</button>
</div>}</div>
          <div className="fs-24">{userData?.plan_name||'\u00A0'}</div>
          </div>
          <button className="fs-48 p-0 lh-90 menu-button" onClick={(e)=>{
              setIsMenuOpen(!isMenuOpen)
              }}>&#8801;
</button>
        </div>
      </div>
      {/* Dynamic Cards Row (80% of page) */}
      {cardData.length>0&&<Menubar fields={[{
        title:"File Name",
        key:"file_name",
        sort:true
      },
      {
        title:"File Type",
        getValue:(data)=>data.file_name.split('.').pop(),
        sort:true,
        key:"file_extension",
        type:'list',
        list:[
          {
          display_value:"Documents",
          value:["doc","docx",'rtf','odt','txt','pages','wpd','wps']  
          },
        {
          display_value:"PDFs",
          value:["pdf"]  
        },
        {
          display_value:"Photos & Images",
          value:["bmp","gif","ico","jpeg","jpg","png","raw","tif","tiff","svg"]  
        },
        {
          display_value:"Presentations",
          value:["pptx","pptm","ppt","potx","potm","pot","pps","ppsm","ppsx","odp"]  
        },
        {
          display_value:"Archive",
          value:["zip","rar","tar","arc","arj"]  
        },
        {
          display_value:"Videos",
          value:["mp4","mov","wmv","avi","avchd","mkv","webm","flv","vob","ogg","ogv","3gp"]  
        },
        {
          display_value:"Audio",
          value:["mp3","wma","wav","vox","voc","tta","aiff","amr","aax"]  
        },
      ]
      }]}
      data={cardData}
      setData={setFilteredCardData}
      />}
      <div className="d-flex flex-wrap file-container" onDrop={onFileDrop} onDragOver={(e)=>{e.preventDefault();e.stopPropagation();}}>
        {filteredCardData.map((card) => (
          <div key={card.created_at} className="mb-3 me-4 text-center fs-24 lh file-wrap position-relative">
            <img className="mb-2" src={card.thumbnail||file}/>
            <div className="position-absolute top-0 hover-menu flex-column align-items-end">
            <Tooltip title="Download" placement="left" arrow> 
            <button className="p-0 d-block plain-button" onClick={(e)=>downloadFile(card)}><img src={download} width={32}/></button>
            </Tooltip>
            <Tooltip title="Share" placement="left" arrow> 
            <button className="p-0 d-block plain-button" onClick={(e)=>{setSelectedFile(card.file_name);setIsShareFile(true);dispatch({type:alertActionTypes.CONFIRM,title:'Share File',subtitle:'Do you want to share '+card.file_name,okbutton:'Yes'});}}><img src={share} width={32}/></button>
            </Tooltip>
            <Tooltip title="Delete" placement="left" arrow> 
<button className="p-0 d-block plain-button" onClick={(e)=>{setSelectedFile(card.file_name);dispatch({type:alertActionTypes.CONFIRM,title:'Delete File',subtitle:'Do you want to delete '+card.file_name,okbutton:'Yes'});}}><img src={trash} width={32}/></button>
</Tooltip>
              </div>
            <div className="text-truncate position-relative">{card.file_name}
            {/* <button className="position-absolute end-0 p-1 fs-24 menu-button" onClick={(e)=>{
              setIsMenuOpen(isMenuOpen?.created_at===card.created_at?false:card)
              }}>&#8942;
</button> */}
</div>
{/*isMenuOpen?.created_at==card.created_at&&<div className="position-absolute d-flex flex-column menu" ref={menu}>
<button onClick={downloadFile}>Download File</button>
<button onClick={(e)=>{dispatch({type:alertActionTypes.CONFIRM,title:'Delete File',subtitle:'Do you want to delete '+isMenuOpen?.file_name,okbutton:'Yes'});}}>Delete File</button>
<button onClick={(e)=>{setIsShareFile(true);dispatch({type:alertActionTypes.CONFIRM,title:'Share File',subtitle:'Do you want to share '+isMenuOpen?.file_name,okbutton:'Yes'});}}>Share File</button>
</div>*/}

          </div>
        ))}
      </div>

        <div className="text-center cld-image">
          <label htmlFor="fileUpload" style={{ cursor: userData?.plan_name?'pointer':'not-allowed' }} >
              <img className={`w-100 ${!userData?.plan_name&&'disabled'}`} src={cld} alt="Upload Icon" onClick={handleFileUpload} />
              {!userData?.plan_name&&<img className="locked-icon" src={locked} />}
              <div className="overlay-text-center img-heading w-100">Drag and Upload </div>
          </label>
          {userData?.plan_name&&<input type="file" id="fileUpload" style={{ display: 'none' }} onChange={handleFileUpload} />}
        </div>
        <BuyNowPopup showPopup={showPlanPopup} closeModal={()=>setShowPlanPopup(false)}/>
        <SharePopup closeModal={()=>setPopupData(null)} shareData={popupData}/>
        <ChangePasswordPopup username={authState?.username} showPopup={showChangePasswordPopup} closeModal={()=>setShowChangePasswordPopup(false)}/>
      </div>
  );
};
export default StoraHub;