import React from "react";
import Header from "./Header";
import storapic from "../../assets/images/stora-merge.png";
import storacurve from "../../assets/images/stora-curve.png";
import tagline from "../../assets/images/tagline.png";
import "./landing.css";
import india from "../../assets/images/india.svg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cld from '../../assets/images/cloud.png';

function StoraLanding() {
  return (
    <>
   
  <Header />
 <div className="cbody">

      <div className="d-sm-flex">
      <div className="tagline hw-font d-block d-sm-none fs-32">
        Chai break se lekar board meeting tak,<br/> files size jyada ho ya thora, use STORA
      </div>
       
        <div className="col-sm-6 text-center tagline d-flex align-items-center justify-content-center">
            <img src={storapic} alt="Centered " className="storapic" />
        </div>
        <div className="col-sm-6">        
        <div className="tagline hw-font d-none d-sm-block fs-40">
        Chai break se lekar board meeting tak,<br/> files size jyada ho ya thora, use STORA
        </div>
        <div className="fs-32">
        Elevating Your Digital Horizon <br/>with Seamless Cloud Excellence.
          </div>
        </div>
        </div>
    
    </div>
    <div className="lp-1-footer">
        Thoughtfully Crafted in <span>Bharat</span>&nbsp;&nbsp;
        <img src={india}  alt="Centered mage" className="ms-1" width="18" />
      </div>
    <div><img src={cld} alt="Centered mage" className="cld-image" /></div>
    </>
  );
} 

export default StoraLanding;
