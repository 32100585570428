
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './Routes';
import { messageStore,authStore }  from './store';
import { useReducer, useEffect } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { alert } from './reducers';
import { alertActionTypes } from './actionTypes';
import { authentication } from './reducers/auth.reducer';
import Popup from './components/popup';
import axios from './services/api';

const {Provider} = messageStore;
const {Provider:AuthProvider}=authStore;
const defaultData=sessionStorage.getItem('user')?JSON.parse(atob(sessionStorage.getItem('user'))):{};
if(defaultData?.tokens?.access_token){
  axios.defaults.headers.common["Authorization"]=`Bearer ${defaultData.tokens.access_token}`;
}
sessionStorage.removeItem('user');
function appHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--vh', ((window.innerHeight-1)*.01) + 'px');
}

function App() {
  const [state, dispatch] = useReducer(alert);
  const [authState,authDispatch] = useReducer(authentication,defaultData);
  if(state && state.message){
    setTimeout(()=>{dispatch({type:alertActionTypes.CLEAR})},5000)
  }
  useEffect(()=>{
     appHeight();
     window.addEventListener('resize',appHeight);
     return ()=>{
       window.removeEventListener('resize',appHeight)
     }
  },[])
  return (
    <Provider value={{state,dispatch}}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
  <AuthProvider value={{authState,authDispatch}}>
    <div className="App">
         <Routes/>
    </div>
    {state && state.message &&
        <div className={`toaster ${state.type}`}>{state.message}</div>
      }
       {
        state && (state.type===alertActionTypes.CONFIRM||state.type===alertActionTypes.ALERT||state.type===alertActionTypes.LOADER) && <Popup state={state} dispatch={dispatch}/> 
      }
    </AuthProvider>
    </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
