import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authStore } from './store';

export const AuthorizedRoute = ({ render, ...rest }) =>{ 
    const {authState}=useContext(authStore);
    return (
    <Route {...rest} render={props => (
         render({...props,...authState})
    )} />
   )
    };