import Modal from 'react-bootstrap/Modal';
const BuyNowPopup=({showPopup,closeModal})=>{
  return <Modal
    size="lg" show={!!showPopup}  backdrop="static"
    onHide={closeModal}
  >
  <Modal.Header closeButton={true} closeVariant="white"/>
  <Modal.Body className="d-flex flex-column justify-content-between align-items-center text-center fs-36 lh-2 px-5">
    <div className="py-4"><b>No plans are active at the moment.</b> Explore our diverse plans and pricing options tailored to your needs. Click to buy now and unlock premium features, elevating your experience.</div>    
    <a className="contact my-5" href="/plans">Buy Now</a>
    </Modal.Body>
  </Modal>
}
export default BuyNowPopup;