import React, { useState,useContext} from 'react'
import { handleSubmit, removeValidation } from '../../services/utils';
import axios from '../../services/api';
import { CREATE_USER_API, ERROR_MESSAGE, GOOGLE_AUTH } from '../../services/constants';
import { authStore ,messageStore} from '../../store';
import { alertActionTypes, authActionTypes } from "../../actionTypes";
import { GoogleLogin } from '@react-oauth/google';
import SignUpPopup from '../SignUpPopup';
import { useHistory } from 'react-router-dom';

function SignUp({handleOnClick}) {
  const history = useHistory();
  const {authDispatch}=useContext(authStore);
  const {dispatch} = useContext(messageStore);
  const [userDetails,setUserDetails]=useState(null);
  const [state, setState] = useState({
    name:"",
    email_id:"",
    profession:"",
    mobile:"",
    password:""
  });
  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    dispatch({type:alertActionTypes.LOADER,title:'Signing Up...'});

    const { name, email_id,profession, mobile,password } = state;
    try {
      const response = await axios.post(CREATE_USER_API,{...state,g_auth:"False"});
      setState({
        name:"",
        email_id:"",
        profession:"",
        mobile:"",
        password:""
      });
      setUserDetails({name,...response?.data});
      dispatch({type:alertActionTypes.CLOSE_POPUP});
      // Handle success if needed
    } catch (error) {
      // Handle errors if needed
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
    }
    finally{
      removeValidation(evt.target);
      evt.target.classList.remove('was-validated');
    }

  };
  const responseMessage = async (gresponse) => {
    try {
      dispatch({type:alertActionTypes.LOADER,title:'Signing Up...'});
      const response = await  axios.get(GOOGLE_AUTH, {
      params:{
        token:gresponse?.credential
      }
      });

      authDispatch({type:authActionTypes.LOGIN_SUCCESS,...response?.data});
      axios.defaults.headers.common["Authorization"]=`Bearer ${response?.data?.tokens?.access_token}`;
      history.push('');      
    } catch (error) {
      dispatch({type:alertActionTypes.ERROR,message:error?.response?.data?.error||ERROR_MESSAGE});
    }};
const errorMessage = (error) => {
    console.log(error);
};
  return (
    <div className="form-container sign-up-container col-sm-6 col-12">
      <form onSubmit={(e)=>{handleSubmit(e,handleOnSubmit)}}>
        <h1 className='sign-up-header'  style={{color:'#00B1FF'}}>Unlock Your Account</h1>
      <div className="w-100">
        <input
          type="text"
          name="name"
          value={state.name}
          onChange={handleChange}
          placeholder="Name"
          className="required"
        />
        <div className="invalid-feedback">Name is required</div>
       </div>
       <div className="w-100">
        <input
          type="text"
          name="email_id"
          value={state.email_id}
          onChange={handleChange}
          placeholder="Email"
          className="required"
          data-pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$"
        />
        <div className="invalid-feedback">{state.email_id?'Email Id should be valid':'Email is required'}</div>
         </div>
         <div className="w-100">
          <input
          type="text"
          name="profession"
          value={state.profession}
          onChange={handleChange}
          placeholder="Profession"
        />
        <div className="invalid-feedback">Profession is required</div>
          </div>
          <div className="w-100">
          <input
          type="text"
          name="mobile"
          value={state.mobile}
          onChange={handleChange}
          placeholder="Mobile"
          className="required"
          data-pattern="\d{10}"
        />
        <div className="invalid-feedback">{state.mobile?'Mobile should be valid':'Mobile is required'}</div>
        </div>
        <div className="w-100">
        <input
          type="password"
          name="password"
          className="required"
          value={state.password}
          onChange={handleChange}
          placeholder="Password"
          data-pattern=".{8,16}"
        />
        <div className="invalid-feedback">{state.password?'Password should be 8-16 characters':'Password is required'}</div>
        </div>
        <button className="mt-2 mb-3" type='submit' >Sign Up</button>
     <div class="or-separater text-blue"></div>
      <GoogleLogin onSuccess={responseMessage} onError={errorMessage} shape="pill" type="icon"/>
      <div className="d-sm-none capitalize text-blue">Already have an account? <button className="plain-button px-0 text-blue" type="button" onClick={(e)=>{document.getElementById('signIn').click();}}><u>Sign In</u></button></div>
         {/* <div>
          <button
            id="signUp"
            onClick={() =>  handleOnClick("signIn")}
          >
            Sign Up
          </button>
        </div> */}
      </form>
      <SignUpPopup userDetails={userDetails} closeModal={()=>setUserDetails(null)}/>
    </div>
  );
}

export default SignUp;